<template>
    <section v-if="isRouteParameterValid" class="hold-transition bg-img" style="background-image:url(/assets/images/bg-2.jpg)" data-overlay=5>
        <div class="container pt-3 h-p100">
            <div class="row h-p100 justify-content-sm-center align-items-center">
                <div class="col-sm-6 col-md-4">
                    <div class="card border-info text-center">
                        <div class="card-header">
                            Set a password for your account
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="onSubmit" class="form-signin" novalidate>
                                <input v-model="password" type="password" class="form-control mb-2" placeholder="Password" required>
                                <p v-if="passwordError">{{passwordErrorMessage}}</p>
                                <input v-model="retypePassword" type="password" class="form-control mb-2" placeholder="Retype Password" required>
                                <p v-if="retypePasswordError">{{retypePasswordErrorMessage}}</p>
                                <button class="btn btn-lg btn-primary btn-block mb-20" @click="confirmPasswordReset">Continue<span v-if="loading" class="spinner-border text-white"></span></button>
                            </form>
                        </div>
                        <!-- <p class="float-right text-white">Already have an account?<a href="login.html" class="text-info m-l-5"> Sign In</a></p> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section v-else class="container h-p100 h-xs-auto">
        <div class="row h-p100 align-items-center">
			<div class="col-12">
				<div class="text-center">
					<h1><i class="fa fa-warning text-danger"></i> PAGE NOT FOUND !</h1>
					<p class="zoom-area">YOU SEEM TO BE TRYING TO FIND HIS WAY HOME</p>
					<section class="error-container">
					  <span class="four"><span class="screen-reader-text">4</span></span>
					  <span class="zero"><span class="screen-reader-text">0</span></span>
					  <span class="four"><span class="screen-reader-text">4</span></span>
					</section>
					<router-link :to="{path: '/'}" class="btn btn-danger mb-5">Back to dashboard</router-link>
				</div>
			</div>
		</div>
    </section>
</template>

<script>
import { repository } from '../../presenter'
export default {
  data () {
    return {
      isRouteParameterValid: false,
      password: '',
      passwordError: false,
      passwordErrorMessage: '',
      retypePassword: '',
      retypePasswordError: false,
      retypePasswordErrorMessage: '',
      loading: false
    }
  },
  mounted () {
    console.log(this.$route.query)
    if (this.$route.query.token != null && this.$route.query.from_email != null) {
      this.isRouteParameterValid = true
    } else {
      this.isRouteParameterValid = false
    }
  },

  methods: {
    resetData () {
      this.passwordError = false
      this.retypePasswordError = false
      this.loading = false
    },
    async confirmPasswordReset () {
      console.log("confirm clicked")
      if (this.loading) return
      this.resetData()
      const detailValidated = this.validateUserData()
      if (!detailValidated) return
      this.loading=true;
      const data = {
        token: this.$route.query.token,
        password: this.password,
        device_id: 'hu9f8uhoekwbfu92hionew9ur0einofiwf',
      }
      console.log("data is ", data);
      const response = await repository.user.completePasswordReset(data)
      console.log("response is ", response)
      this.loading=false;
      if (response.success) {
        this.showDashboard()
        return
      }
      console.log("should show error")
      this.showError(response.data)
    },

    validateUserData() {
      console.log("validating...")
      if (!this.password) {
        console.log("password false")
        this.passwordError=true;
        this.passwordErrorMessage='Enter a valid password';
        return false;
      }
      if (this.retypePassword !== this.password) {
        console.log("retype false")
        this.retypePasswordError=true;
        this.retypePasswordErrorMessage='Passwords do not match';
        return false;
      }
      console.log("validated truly")
      return true;
    },

    showDashboard () {
      this.$router.replace({ name: 'Dashboard' })
    },

    showError (message) {
      this.passwordError = true
      this.passwordErrorMessage = message
    }
  }
}
</script>
